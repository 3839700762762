import Container from "~/components/layout/Container";
import { Icon } from "~/components/ui/icon";
import { Social } from "./Social";

export default function Footer() {
  return (
    <footer className="bg-[#333333] text-white">
      <Container className="py-4 flex flex-wrap">
        <div className="flex flex-wrap w-full">
          <div className="flex items-center w-3/12">
            <a href="/" className="w-full flex items-center">
              <img className="grayscale-100 brightness-200" src="/assets/AL-DE-muhendislik-logo.svg" alt="UTB Support" width={120} height={20} />
            </a>
          </div>
          <div className="w-6/12 px-10 flex items-center">
            <ul className="flex justify-between w-full [&>li]:text-[14px] [&>li]:font-medium [&>li]:text-white [&>li:before]:content-['\203A'] [&>li:before]:mr-1 [&>li:hover:before]:text-blue-400">
              <li><a href="/">Anasayfa</a></li>
              <li><a href="/kurumsal">Kurumsal</a></li>
              <li><a href="/hizmetlerimiz">Hizmetlerimiz</a></li>
              <li><a href="/blog">Blog</a></li>
              <li><a href="/iletisim">İletişim</a></li>
            </ul>
          </div>
          <div className="w-auto fixed right-8 bottom-8 z-50">
            <a href="https://wa.me/905331234567" target="_blank" rel="noreferrer" className="bg-[#25d366] block animate-bound shadow-3  shadow-black/50 rounded-full hover:text-[#25d366] hover:bg-[#fff]">
              <Icon name="mingcute:whatsapp-fill" className="w-14 h-14 m-2" />
            </a>
          </div>
        </div>
        <div className="flex flex-wrap items-center font-extralight font-source-sans-pro not-italic justify-between -mt-8">
          <Container className="flex items-center">
            <div className="w-4/12"></div>
            <div className="w-4/12 text-center text-[14px]">
              <p>&copy; {new Date().getFullYear()} Tüm hakları saklıdır.</p>
            </div>
            <div className="w-4/12 flex items-center gap-2 mr-4 justify-end">
              <Social />
            </div>
          </Container>
        </div>
      </Container>
    </footer >
  );
}